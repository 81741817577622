<template>
  <ShadowCard
    :param="{ name: 'Transfers', showButtons: true }"
    :isLoading="isLoading">
    <EditableTable
      :fields="fields"
      :storeData="storeData"
      :storePath="storePath"
      :id_property="id_property"
      :item_name="'Transfer'"
      :initialSort="[{ field: 'Date', type: 'desc' }]"
      :usesPortfolioSelector="true"/>
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import EditableTable from "./EditableTable.vue";

export default {
  name: "transfers",
  components: { EditableTable, ShadowCard },

  data() {
    return {
      isLoading: true,
      storeData: "TRANSFERS",
      storePath: "transfers",
      id_property: "Transfer_ID",
      fields: [
        {
          editable: false,
          field: "Transfer_ID",
          label: "ID",
          width: "60px",
          thClass: "text-center",
          tdClass: "text-center",
          needToBeInteger: true,
          filterOptions: {
            enabled: true,
            placeholder: "ID",
            trigger: "keyup",
          },
        },
        {
          type: "date",
          width: "90px",
          field: "Date",
          label: "Date",
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          formatFn: this.dateFormatFn,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "yyyy-MM-dd",
          validation: "required",
          filterOptions: {
            enabled: true,
            placeholder: "Date",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          editable: true,
          options: this.portfolioList,
          field: "Portfolio_ID",
          label: "Portfolio ID",
          width: "115px",
          thClass: "text-center",
          tdClass: "text-left",
          validation: "required",
          filterOptions: {
            enabled: true,
            placeholder: "Portfolio ID",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          editable: true,
          field: "Description",
          label: "Description",
          width: "200px",
          thClass: "text-center",
          tdClass: "text-left",
          validation: "required",
          filterOptions: {
            enabled: true,
            placeholder: "Description",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          editable: true,
          options: this.currenciesList,
          field: "Currency",
          label: "Ccy",
          width: "62px",
          thClass: "text-center",
          tdClass: "text-center",
          sumColumn: true,
          filterOptions: {
            enabled: true,
            placeholder: "Ccy",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          editable: true,
          precision: 2,
          prefix: "$",
          field: "Credit",
          label: "Credit",
          width: "80px",
          thClass: "text-center custom-th-class",
          tdClass: "text-right custom-td-class",
          summable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Credit",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          editable: true,
          precision: 2,
          prefix: "$",
          field: "Debit",
          label: "Debit",
          width: "80px",
          thClass: "text-center",
          tdClass: "text-right",
          summable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Debit",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          editable: false,
          precision: 2,
          field: "Amount",
          label: "Amount",
          width: "80px",
          thClass: "text-center",
          tdClass: "text-right",
          summable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Amount",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          precision: 2,
          editable: false,
          field: "USD_Amount",
          label: "USD Amt.",
          width: "80px",
          thClass: "text-center",
          tdClass: "text-right",
          summable: true,
          filterOptions: {
            enabled: true,
            placeholder: "USD Amount",
            trigger: "keyup",
          },
        },
        {
          width: "80px",
          field: "Actions",
          label: "Actions",
          actions: "actions",
          thClass: "text-center",
          tdClass: "text-center",
          html: true,
        },
      ],
    };
  },
  mounted() {
    this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");

    this.setEditableFields();
  },
  methods: {
    updateSelectOptions(fieldName, options) {
      const fieldToUpdate = this.fields.find(
        (field) => field.field === fieldName
      );
      if (fieldToUpdate) {
        fieldToUpdate.options = options;
      }
    },
    dateFormatFn(value) {
      try {
        let formattedDate = new Date(value).toISOString().split("T")[0];

        return formattedDate;
      } catch (error) {
        return value.substring(0, 10);
      }
    },
    setFieldId() {
      this.$store.commit("SET_TABLE_FIELD_ID", this.id_property);
    },
    setEditableFields() {
      this.$store.commit("SET_TABLE_EDITABLEFIELDS", this.editableFields);
    },
  },
  watch: {
    portfolioList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("Portfolio_ID", newVal);
      },
    },
    currenciesList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("Currency", newVal);
      },
    },
  },
  computed: {
    editableFields() {
      let editableFields = this.fields.filter((field) => field.editable);

      return editableFields;
    },
    portfolioList() {
      return this.$store.state.portfolioList.map((portfolio) => ({
        value: portfolio.portfolio_id,
        label: portfolio.portfolio_id,
      }));
    },
    currenciesList() {
      return this.$store.state.currenciesList.map((currency) => ({
        value: currency.currency_code,
        label: currency.currency_code + " - " + currency.description,
      }));
    },
  },
};
</script>

<style></style>
